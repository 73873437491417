/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchCategoriesSuccess,
  fetchCategoriesError,
} from './BlogCategoriesActions';

// const delayReject = time => new Promise((_, reject) => setTimeout(() => {
//  reject(new Error('You provided invalid creds.'))
// }, time));

const delayResolve = (time) => new Promise((resolve) => setTimeout(() => resolve('token'), time));

const getCategories = () => delayResolve(2000).then((response) => ['faith', 'psychology', 'art', 'challenges', 'goals']);

function* fetchCategories(action) {
  try {
    const data = yield call(getCategories);

    yield put(fetchCategoriesSuccess(data));
  } catch (e) {
    yield put(fetchCategoriesError(e));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* blogCategoriesSaga() {
  yield takeLatest('FETCH_CATEGORIES', fetchCategories);
}
