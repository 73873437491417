import React from 'react';
import { PropTypes } from 'prop-types';

import './Footer.scss';
import { connect } from 'react-redux';
import SocialMediaGroup from '../SocialMediaGroup/SocialMediaGroup';

export const Footer = ({ loading }) => (
  <div className="footer">
    <div className="sm-container">
      <SocialMediaGroup
        loading={loading}
        height="30px"
        gridGap="20px"
      />
    </div>
  </div>
);

Footer.propTypes = {
  loading: PropTypes.bool,
};

Footer.defaultProps = {
  loading: false,
};

export default connect(undefined, undefined)(Footer);
