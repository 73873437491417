export const fetchCategories = () => ({
  type: 'FETCH_CATEGORIES',
});

export const fetchCategoriesSuccess = (categories) => ({
  type: 'FETCH_CATEGORIES_SUCCESS',
  payload: { categories },
});

export const fetchCategoriesError = (error) => ({
  type: 'FETCH_CATEGORIES_ERROR',
  payload: { error },
});

export const selectCategory = (category) => ({
  type: 'SELECT_CATEGORY',
  payload: { category },
});
