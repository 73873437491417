/* eslint-disable import/prefer-default-export */
export const isAppLoading = (state) => {
  const loadingChecks = [
    state.blog.loadingPosts,
    state.about.loadingInfo,
    state.projects.loadingProjects,
    state.contact.loading,
  ];

  return loadingChecks.some((check) => check);
};
