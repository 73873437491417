import React from 'react';
import { PropTypes } from 'prop-types';

import './BlogPostItem.scss';
import { connect } from 'react-redux';
import PageBody from '../../components/PageBody/PageBody';
import Post from '../../components/Post/Post';

const BlogPostItem = ({ match }) => (
  <PageBody>
    <Post postId={match.params.id} />
  </PageBody>
);

BlogPostItem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostItem);
