import React from 'react';
import './MenuModal.scss';

import { PropTypes } from 'prop-types';
import { ReactComponent as About } from '../Header/about2.svg';
import { ReactComponent as Projects } from '../Header/projects2.svg';
import { ReactComponent as Blog } from '../Header/blog2.svg';
import { ReactComponent as Contact } from '../Header/contact2.svg';

import HeaderRouteLink from '../HeaderRouteLink/HeaderRouteLink';
import SocialMediaGroup from '../SocialMediaGroup/SocialMediaGroup';

const classNames = require('classnames');

const MenuModal = ({ routeChange, hidden }) => {
  const overlayClassNames = classNames({
    'Menu-Overlay': true,
    hidden,
  });

  return (
    <div className={overlayClassNames}>
      <HeaderRouteLink link="about" className="page-link">
        <About onClick={() => routeChange()} />
      </HeaderRouteLink>
      <HeaderRouteLink link="blog" className="page-link">
        <Blog onClick={() => routeChange()} />
      </HeaderRouteLink>
      <HeaderRouteLink link="projects" className="page-link">
        <Projects onClick={() => routeChange()} />
      </HeaderRouteLink>
      <HeaderRouteLink link="contact" className="page-link">
        <Contact onClick={() => routeChange()} />
      </HeaderRouteLink>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}
      >
        <SocialMediaGroup gridGap="25px" />
      </div>
    </div>
  );
};

MenuModal.propTypes = {
  hidden: PropTypes.bool.isRequired,
  routeChange: PropTypes.func.isRequired,
};

export default MenuModal;
