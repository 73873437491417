/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { submitEmailSuccess, submitEmailError } from './ContactActions';
import { appConfig } from '../../environments';

const postEmail = (info) => fetch(`${appConfig.internalAppUrl}/contact`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...info, website: 'kevynhale.com' }),
  // eslint-disable-next-line max-len
  }).then((response) => (new Promise((resolve) => setTimeout(() => resolve(response.json()), 1000))));

function* submitEmail(action) {
  try {
    yield call(postEmail, action.payload.info);

    yield put(submitEmailSuccess());
  } catch (e) {
    yield put(submitEmailError(e));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* contactSaga() {
  yield takeLatest('SUBMIT_EMAIL', submitEmail);
}
