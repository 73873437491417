// Clears the inputs and the removes the banners
export const resetForm = () => ({
  type: 'RESET_FORM',
});

export const submitEmail = (info) => ({
  type: 'SUBMIT_EMAIL',
  payload: { info },
});

export const submitEmailSuccess = () => ({
  type: 'SUBMIT_EMAIL_SUCCESS',
});

export const submitEmailError = (error) => ({
  type: 'SUBMIT_EMAIL_ERROR',
  payload: { error },
});
