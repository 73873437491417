export const initialAboutState = {
  loadingInfo: false,
};

export default (state = initialAboutState, action) => {
  switch (action.type) {
    case 'LOADING_ABOUT':
      return {
        ...state,
        loadingInfo: true,

      };

    case 'FINISHED_LOADING_ABOUT':
      return {
        ...state,
        loadingInfo: false,
      };

    default:
      return state;
  }
};
