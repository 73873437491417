import { combineReducers } from 'redux';

import BlogReducer, { initialBlogState } from './blog/BlogReducer';
import AboutReducer, { initialAboutState } from './about/AboutReducer';
import ProjectsReducer, { initialProjectsState } from './projects/ProjectsReducer';
import BlogCategoriesReducer, {
  initialBlogCategoriesState,
} from './blogCategories/BlogCategoriesReducer';
import ContactReducer, { initialContactState } from './contact/ContactReducer';

export const initialStoreState = {
  blog: initialBlogState,
  about: initialAboutState,
  projects: initialProjectsState,
  blogCategories: initialBlogCategoriesState,
  contact: initialContactState,
};

const appReducer = combineReducers({
  blog: BlogReducer,
  about: AboutReducer,
  projects: ProjectsReducer,
  blogCategories: BlogCategoriesReducer,
  contact: ContactReducer,
});

export default (state, action) => appReducer(state, action);
