/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { PropTypes } from 'prop-types';

import { loadingAbout, startLoadingAboutDelay } from '../../store/about/AboutActions';

import './About.scss';
import PageBody from '../../components/PageBody/PageBody';
import ThreeDotsLoader from '../../components/ThreeDotsLoader/ThreeDotsLoader';

const classNames = require('classnames');

const GET_ABOUT = gql`
query GET_ABOUT {
  page(id: "cG9zdDoxMg==") {
    content
  }
}
`;

const About = ({ loading, loadingAboutInt, startLoadingAboutDelayInt }) => {
  useEffect(() => {
    loadingAboutInt();
  }, []);

  const onCompleted = () => {
    startLoadingAboutDelayInt();
  };

  const { data } = useQuery(GET_ABOUT, {
    onCompleted,
  });

  const content = data ? data.page.content : null;

  const articleClassnames = classNames({
    loading,
    'post-article': true,
  });

  return (
    <PageBody>
      <ThreeDotsLoader hidden={!loading} />
      <article className={articleClassnames}>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </article>
    </PageBody>
  );
};
About.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingAboutInt: PropTypes.func.isRequired,
  startLoadingAboutDelayInt: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loadingAboutInt: () => dispatch(loadingAbout()),
  startLoadingAboutDelayInt: () => dispatch(startLoadingAboutDelay()),
});

const mapStateToProps = (state) => ({
  loading: state.about.loadingInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
