import React, { useState } from 'react';
import ApolloClient from 'apollo-boost';

import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Header from './components/Header/Header';
import SubHeader from './components/SubHeader/SubHeader';

import About from './pages/About/About';
import Blog from './pages/Blog/Blog';
import Projects from './pages/Projects/Projects';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import MenuModal from './components/MenuModal/MenuModal';
import BlogPostItem from './pages/BlogPostItem/BlogPostItem';
import ProjectItem from './pages/ProjectItem/ProjectItem';
import { Footer } from './components/Footer/Footer';
import { appConfig } from './environments';

const client = new ApolloClient({
  uri: appConfig.wordpressUrl,
});

const App = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  const menuHandler = (currentShowMenu) => {
    setShowMenu(!currentShowMenu);
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <MenuModal hidden={!showMenu} routeChange={() => menuHandler(showMenu)} />
          <Header menuHandler={() => menuHandler(showMenu)} menuOpen={showMenu} />
          <SubHeader showMenu={showMenu} />

          <Switch>
            <Route path="/about" component={About} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:id" component={BlogPostItem} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/:id" component={ProjectItem} />
            <Route path="/contact" component={Contact} />
            <Route path="/" component={Home} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </ApolloProvider>
  );
};

export default App;
