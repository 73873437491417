import React, { useEffect, useLayoutEffect } from 'react';
import './PageBody.scss';
import { PropTypes } from 'prop-types';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const PageBody = ({ minHeight, children }) => {
  const { trackPageView } = useMatomo();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  // Track page view
  useEffect(() => {
    trackPageView();
  }, []);

  const styles = {
    minHeight,
  };

  return (
    <div className="page-body" style={styles}>
      {children}
    </div>
  );
};

PageBody.propTypes = {
  minHeight: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageBody.defaultProps = {
  minHeight: '100px',
};

export default PageBody;
