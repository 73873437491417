/* eslint-disable import/prefer-default-export */
import createLocalConfig from './local';
import createProdConfig from './prod';

let config;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  config = createLocalConfig();
} else {
  config = createProdConfig();
}

export const appConfig = config;
