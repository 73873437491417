import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';

import { blogSaga } from './blog/BlogSaga';
import { aboutSaga } from './about/AboutSaga';
import { projectsSaga } from './projects/ProjectsSaga';
import { blogCategoriesSaga } from './blogCategories/BlogCategoriesSaga';
import { contactSaga } from './contact/ContactSaga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
middlewares.push(logger);

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(blogSaga);
sagaMiddleware.run(aboutSaga);
sagaMiddleware.run(projectsSaga);
sagaMiddleware.run(blogCategoriesSaga);
sagaMiddleware.run(contactSaga);

export default store;
