export const initialBlogState = {
  loadingPosts: false,
};

export default (state = initialBlogState, action) => {
  switch (action.type) {
    case 'LOADING_POSTS':
      return {
        ...state,
        loadingPosts: true,
      };

    case 'FINISHED_LOADING_POSTS':
      return {
        ...state,
        loadingPosts: false,
      };

    default:
      return state;
  }
};
