import React from 'react';
import './MessageBubble.scss';
import PropTypes from 'prop-types';

const classNames = require('classnames');

export const MessageBubble = ({
  color, backgroundColor, borderColor, show, children,
}) => {
  const styles = {
    color: color || 'black',
    backgroundColor: backgroundColor || 'white',
    borderColor: borderColor || 'black',
  };

  const messageClasses = classNames({
    'message-bubble': true,
    show,
  });

  return (
    <div className={messageClasses} style={styles}>
      {children}
    </div>
  );
};

MessageBubble.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const ErrorMessageBubble = ({ show, children }) => (
  <MessageBubble
    show={show}
    color="#a80f0f"
    borderColor="#e8b8b8"
    backgroundColor="#ffdcdc"
  >
    {children}
  </MessageBubble>
);

ErrorMessageBubble.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const SuccessMessageBubble = ({ show, children }) => (
  <MessageBubble
    show={show}
    color="#126436"
    borderColor="#6bd36b"
    backgroundColor="#d6f6e4"
  >
    {children}
  </MessageBubble>
);

SuccessMessageBubble.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
