import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import './PostsBody.scss';
import { PropTypes } from 'prop-types';
import LoaderGridItem from '../LoaderGridItem/LoaderGridItem';
import BlogListItem from '../BlogListItem/BlogListItem';
import { loadingPosts, startLoadingPostsDelay } from '../../store/blog/BlogActions';

const className = require('classnames');

const GET_POSTS = gql`
query GET_POSTS($tagSlug: [String]) {
  posts(where: {tagSlugIn: $tagSlug, categoryNotIn: 4}) {
    edges {
      node {
        id
        title
        date
        tags {
          edges {
            node {
              name
              id
            }
          }
        }
        excerpt
        featuredImage{
          node {
            uri
            sourceUrl
          }
        }
      }
    }
  }
}
`;

const PostsBody = ({
  selectedTag, postsLoading, startLoadingPostsDelayInt, loadingPostsInt,
}) => {
  const onCompleted = () => {
    startLoadingPostsDelayInt();
  };

  const variables = { tagSlug: selectedTag === '' ? null : selectedTag };
  const { data, loading } = useQuery(GET_POSTS, {
    onCompleted,
    variables,
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    loadingPostsInt();
  }, [loading]);

  const smallScreen = window.innerWidth > 750;

  const loaderContainerClasses = className({
    'posts-container': true,
    'loading-container': true,
    'loading-loading-container': postsLoading,
  });

  const postsContainerClasses = className({
    'posts-container': true,
    'hidden-container': data === undefined,
    loading: postsLoading,
    single: data ? data.posts.edges.length === 1 : false,
  });

  const itemClasses = className({
    'post-item': true,
  });

  return (
    <div className="posts-body">
      <div className={loaderContainerClasses}>
        <LoaderGridItem loading={postsLoading} />
        <LoaderGridItem loading={postsLoading} />
        {smallScreen ? <LoaderGridItem loading={postsLoading} /> : null}
        {smallScreen ? <LoaderGridItem loading={postsLoading} /> : null}
      </div>
      {
            data
              ? (
                <div className={postsContainerClasses}>
                  {data.posts.edges.map((edge) => {
                    const { node: post } = edge;

                    const tags = (post.tags ? post.tags.edges : []).map((tag) => tag.node.name);

                    const featuredImageUrl = post.featuredImage ? post.featuredImage.node.sourceUrl : '';

                    return (
                      <BlogListItem
                        key={post.id}
                        className={itemClasses}
                        id={post.id}
                        excerpt={post.excerpt}
                        title={post.title}
                        tags={tags}
                        featuredImageUrl={featuredImageUrl}
                      />
                    );
                  })}
                </div>
              ) : null
          }
    </div>
  );
};

PostsBody.propTypes = {
  selectedTag: PropTypes.string.isRequired,
  postsLoading: PropTypes.bool.isRequired,
  startLoadingPostsDelayInt: PropTypes.func.isRequired,
  loadingPostsInt: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  postsLoading: state.blog.loadingPosts,
  selectedTag: state.blogCategories.selected,
});

const mapDispatchToProps = (dispatch) => ({
  startLoadingPostsDelayInt: () => dispatch(startLoadingPostsDelay()),
  loadingPostsInt: () => dispatch(loadingPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsBody);
