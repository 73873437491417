import React from 'react';
import { connect } from 'react-redux';

import BlogCategories from '../../components/Categories/Categories';
import PostsBody from '../../components/PostsBody/PostsBody';

import './Blog.scss';
import PageBody from '../../components/PageBody/PageBody';

const Blog = () => (
  <PageBody minHeight="700px">
    <BlogCategories />
    <PostsBody />
  </PageBody>
);

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
