/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import './SplashEffectButton.scss';

const classNames = require('classnames');

const SplashEffectButton = ({ children }) => {
  const [clicked, setClicked] = useState(false);

  const clickEffect = () => {
    setClicked(true);
    setTimeout(() => { setClicked(false); }, 900);
  };

  const buttonEffectClasses = classNames({
    'button-effect': true,
    'button-effect-clicked': clicked,
  });

  return (
    <div
      className="button-parent"
      type="button"
      onClick={clickEffect}
    >
      <div className={buttonEffectClasses} />
      {children}
    </div>
  );
};

SplashEffectButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SplashEffectButton;
