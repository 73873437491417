import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { loadingProjects, startLoadingProjectsDelay } from '../../store/projects/ProjectsActions';
import PageBody from '../../components/PageBody/PageBody';

import './Projects.scss';
import LoaderGridItem from '../../components/LoaderGridItem/LoaderGridItem';
import ProjectListItem from '../../components/ProjectListItem/ProjectListItem';

const classNames = require('classnames');

const GET_POSTS = gql`
query GET_POSTS($tagSlug: [String]) {
  posts(where: {tagSlugIn: $tagSlug, categoryId: 4}) {
    edges {
      node {
        id
        title
        date
        tags {
          edges {
            node {
              name
              id
            }
          }
        }
        excerpt
        featuredImage{
          node {
            uri
            sourceUrl
          }
        }
      }
    }
  }
}
`;

const Projects = ({ loading, loadingProjectsInt, startLoadingProjectsDelayInt }) => {
  useEffect(() => {
    loadingProjectsInt();
  }, []);

  const onCompleted = () => {
    startLoadingProjectsDelayInt();
  };

  const { data } = useQuery(GET_POSTS, {
    onCompleted,
    variables: { tagSlug: null },
  });

  const loaderClasses = classNames({
    'projects-grid': true,
    'loading-container': true,
    'loading-loading-container': loading,
  });

  const gridClasses = classNames({
    'projects-grid': true,
    'hidden-grid': data === undefined,
    loading,
  });

  return (

    <PageBody minHeight="650px">
      <div className={loaderClasses}>
        <LoaderGridItem loading={loading} />
        <LoaderGridItem loading={loading} />
      </div>
      {
              data
                ? (
                  <div className={gridClasses}>
                    {data.posts.edges.map((edge, index) => {
                      const { node: project } = edge;

                      return (
                        <ProjectListItem
                          key={project.id}
                          className="grid-item"
                          project={project}
                          flexDirection={index % 2 === 0 ? 'row-reverse' : ''}
                        />
                      );
                    })}
                  </div>
                )
                : null
            }

    </PageBody>

  );
};

Projects.propTypes = {
  loading: PropTypes.bool.isRequired,
  startLoadingProjectsDelayInt: PropTypes.func.isRequired,
  loadingProjectsInt: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  startLoadingProjectsDelayInt: () => dispatch(startLoadingProjectsDelay()),
  loadingProjectsInt: () => dispatch(loadingProjects()),
});

const mapStateToProps = (state) => ({
  loading: state.projects.loadingProjects,
  projects: state.projects.projects,
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
