/* eslint-disable react/no-danger */
import React from 'react';
import './ProjectListItem.scss';

import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const ProjectListItem = ({ project, flexDirection }) => {
  const projectRoute = `/projects/${project.id}`;

  const styles = {
    flexDirection: flexDirection || 'start',
  };

  const featuredImage = project.featuredImage ? project.featuredImage.node.sourceUrl : '';

  return (
    <Link className="project-item-container" to={projectRoute} style={styles}>
      <div className="project-image">
        <img src={featuredImage} alt="project" />
      </div>
      <div className="project-text">
        <h2>{project.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: project.excerpt }} />
      </div>
    </Link>
  );
};

ProjectListItem.propTypes = {
  flexDirection: PropTypes.string.isRequired,
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        sourceUrl: PropTypes.string.isRequired,
      }),
    }).isRequired, // TODO fix this.
  }).isRequired,
};

export default ProjectListItem;
