import React from 'react';
import { Link } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as About } from './about2.svg';
import { ReactComponent as Projects } from './projects2.svg';
import { ReactComponent as Blog } from './blog2.svg';
import { ReactComponent as Contact } from './contact2.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Exit } from './exit.svg';
import './Header.scss';
import HeaderRouteLink from '../HeaderRouteLink/HeaderRouteLink';
import SocialMediaGroup from '../SocialMediaGroup/SocialMediaGroup';
import SplashEffectButton from '../SplashEffectButton/SplashEffectButton';

const Header = ({ menuOpen, menuHandler }) => (
  <>
    <header className="App-header">
      <Link to="/">
        <Logo className="App-logo" alt="logo" />
      </Link>

      <HeaderRouteLink link="about">
        <About />
      </HeaderRouteLink>
      <HeaderRouteLink link="blog" className="page-link">
        <Blog />
      </HeaderRouteLink>

      <div className="center-piece" />

      <HeaderRouteLink link="projects" className="page-link">
        <Projects />
      </HeaderRouteLink>
      <HeaderRouteLink link="contact" className="page-link">
        <Contact />
      </HeaderRouteLink>

      <div className="end-piece-sm" dir="rtl">
        <SocialMediaGroup height="20px" gridGap="10px" />
      </div>
      <div className="end-piece">

        {menuOpen ? (
          <SplashEffectButton>
            <Exit className="Exit" onClick={menuHandler} />
          </SplashEffectButton>
        ) : (
          <SplashEffectButton>
            <Menu className="Menu" onClick={menuHandler} />
          </SplashEffectButton>
        )}

      </div>
    </header>
    <header className="header-placeholder" />
  </>
);

Header.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  menuHandler: PropTypes.func.isRequired,
};

export default Header;
