import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { resetForm, submitEmail } from '../../store/contact/ContactActions';

import './ContactForm.scss';
import ThreeDotsLoader from '../../components/ThreeDotsLoader/ThreeDotsLoader';
import {
  ErrorMessageBubble,
  SuccessMessageBubble,
} from '../../components/MessageBubble/MessageBubble';

const classNames = require('classnames');

const initialState = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameValid: true,
      email: '',
      emailValid: true,
      subject: '',
      subjectValid: true,
      message: '',
      messageValid: true,
    };
  }

  componentDidMount(nextProps) {
    const { loading, resetFormInternal } = this.props;
    resetFormInternal();
    if (loading && !nextProps.loading) {
      if (!nextProps.hasError) {
        this.setState(initialState);
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { emailSent } = this.props;

    if (nextProps.emailSent !== emailSent) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);
    }
  }

  submit() {
    /* form validation */
    let pass = true;
    const {
      name, email, message, subject,
    } = this.state;
    const { submit } = this.props;

    if (name === '') {
      this.setState({ nameValid: false });
      pass = false;
    } else {
      this.setState({ nameValid: true });
    }

    if (
      email === ''
      || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      this.setState({ emailValid: false });
      pass = false;
    } else {
      this.setState({ emailValid: true });
    }

    if (message === '') {
      this.setState({ messageValid: false });
      pass = false;
    } else {
      this.setState({ messageValid: true });
    }
    if (pass) {
      submit({
        name,
        email,
        subject,
        message,
      });
    }
  }

  render() {
    const {
      nameValid, emailValid, subjectValid, messageValid, name, email, subject, message,
    } = this.state;

    const { emailSent, loading, hasError } = this.props;

    /* update styles passed off of validation results */
    const nameClasses = classNames({
      form: true,
      'form-name': true,
      invalid: !nameValid,
    });

    const emailClasses = classNames({
      form: true,
      'form-email': true,
      invalid: !emailValid,
    });

    const subjectClasses = classNames({
      form: true,
      'form-subject': true,
      invalid: !subjectValid,
    });

    const messageClasses = classNames({
      form: true,
      'form-message': true,
      invalid: !messageValid,
    });

    const submitClasses = classNames({
      'form-submit': true,
      loading,
    });

    const failed = [
      nameValid,
      emailValid,
      subjectValid,
      messageValid,
    ].some((value) => value === false);

    return (
      <div className="form-container">
        <input
          placeholder="Name"
          type="text"
          className={nameClasses}
          onChange={(event) => this.setState({ name: event.target.value })}
          value={name}
        />
        <input
          placeholder="email"
          type="email"
          className={emailClasses}
          onChange={(event) => this.setState({ email: event.target.value })}
          value={email}
        />
        <input
          placeholder="subject"
          type="text"
          className={subjectClasses}
          onChange={(event) => this.setState({ subject: event.target.value })}
          value={subject}
        />
        <textarea
          placeholder="message"
          type="text"
          className={messageClasses}
          onChange={(event) => this.setState({ message: event.target.value })}
          value={message}
        />
        <div className={submitClasses} onClick={() => this.submit()} onKeyPress={() => {}} role="button" tabIndex="0">
          <ThreeDotsLoader hidden={!loading} />
          {!loading ? 'Submit' : null}
        </div>
        <ErrorMessageBubble show={failed}>
          Please fill out each required input correctly.
        </ErrorMessageBubble>
        <ErrorMessageBubble show={hasError}>
          There was en error sending the message. Feel free to reach me directly at
          kevyn@kevynhale.com
        </ErrorMessageBubble>
        <SuccessMessageBubble show={emailSent}>
          Your email was successfuly sent!
        </SuccessMessageBubble>
      </div>
    );
  }
}

ContactForm.propTypes = {
  emailSent: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  resetFormInternal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
  hasError: state.contact.hasError,
  error: state.contact.error,
  emailSent: state.contact.emailSent || false,
});

const mapDispatchToProps = (dispatch) => ({
  submit: (info) => dispatch(submitEmail(info)),
  resetFormInternal: () => dispatch(resetForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
