import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { finishedLoadingPosts } from './BlogActions';
import { delayResolve } from '../../utils';

function* startDelay() {
  yield call(delayResolve);
  yield put(finishedLoadingPosts());
}

// eslint-disable-next-line import/prefer-default-export
export function* blogSaga() {
  yield takeLatest('START_LOADING_POSTS_DELAY', startDelay);
}
