export const loadingProjects = () => ({
  type: 'LOADING_PROJECTS',
});

export const finishedLoadingProjects = () => ({
  type: 'FINISHED_LOADING_PROJECTS',
});

export const startLoadingProjectsDelay = () => ({
  type: 'START_LOADING_PROJECTS_DELAY',
});
