/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';

import './Post.scss';

import gql from 'graphql-tag';
import { PropTypes } from 'prop-types';
import { ReactComponent as ShareIcon } from './share-icon-3.svg';
import ThreeDotsLoader from '../ThreeDotsLoader/ThreeDotsLoader';
import { getContentReadTime, formatPostDate } from '../../utils';

const classNames = require('classnames');

const GET_POST = gql`
query GET_POSTS($postId: ID!) {
  post(id: $postId) {
    content
    title
    date
    author {
      node {
        avatar {
          url
        }
        firstName
        lastName
        url
      }
    }
  }
}
`;

const Post = ({ postId }) => {
  const onCompleted = () => {};

  const { data, loading } = useQuery(GET_POST, {
    onCompleted,
    variables: { postId },
  });

  const [circleClicked, setCircleClicked] = useState(false);

  const articleClassnames = classNames({
    loading,
    'post-article': true,
  });

  const shareCircleClasses = classNames({
    'share-icon-circle': true,
    'share-icon-circle-click': circleClicked,
  });

  if (!data) {
    return <ThreeDotsLoader hidden={!loading} />;
  }

  const {
    post: {
      author, title, date, content,
    },
  } = data;

  const shareHandler = async () => {
    setCircleClicked(true);
    if (navigator.canShare) {
      await navigator.share({ title, url: window.location });
    }
    setTimeout(() => { setCircleClicked(false); }, 900);
  };

  return (
    <>
      <ThreeDotsLoader hidden={!loading} />
      <article className={articleClassnames}>
        <header className="article-header">
          <h2>{title}</h2>
          <div className="article-sub-header">
            <div className="avatar">
              <img
                className="author-avatar"
                src={author.node.avatar.url}
                alt="author avatar"
              />
            </div>
            <div className="article-info">
              <div>
                <span className="author-name">
                  {author.node.firstName}
                  {' '}
                  {author.node.lastName}
                </span>
              </div>
              <div>
                <span className="read-time">
                  <time>{formatPostDate(date)}</time>
                  {' '}
                  &#183;
                  {' '}
                  {getContentReadTime(content)}
                  {' '}
                  min read
                </span>
              </div>

            </div>

            <div className="share-icon">
              <div type="button" onClick={shareHandler}>
                <div className={shareCircleClasses} />
                <ShareIcon />
              </div>
            </div>

          </div>

        </header>

        <p dangerouslySetInnerHTML={{ __html: content }} />
      </article>
    </>
  );
};

Post.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default Post;
