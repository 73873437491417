import React from 'react';
import { ReactComponent as Landscape } from './landscape-background.svg';
import './Home.scss';
import peace from './kevyn-peace.png';
import banner from './website-header-modified.jpg';

function Home() {
  return (
    <div className="Home-Body">
      <div className="first-section">
        <img src={peace} alt="peace" className="peace" />
        <img src={banner} alt="banner" className="banner" />
        <div className="tag-line">
          <h1>I&apos;m a single father, adventurer, engineer, teacher and entrepreneur!</h1>
        </div>
      </div>
      <div className="second-section">
        <Landscape className="Landscape" />
      </div>
    </div>
  );
}

export default Home;
