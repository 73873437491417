/* eslint-disable react/no-danger */
import React from 'react';
import './BlogListItem.scss';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const BlogListItem = ({
  id, tags, featuredImageUrl, excerpt, title,
}) => {
  const postRoute = `/blog/${id}`;

  return (
    <Link className="blog-item-container" to={postRoute}>
      <div>
        <img src={featuredImageUrl} alt="post" />
      </div>
      <div>
        <h2>{title}</h2>
      </div>
      <div>
        <p dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
      <div className="categories">
        {tags.map((tag) => (
          <span key={tag}>{tag}</span>
        ))}
      </div>
    </Link>
  );
};

BlogListItem.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  featuredImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};

export default BlogListItem;
