import React, { useState, useRef, useEffect } from 'react';

import './SubHeader.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { isAppLoading } from '../../store/AppSelectors';

import { ReactComponent as Hiker } from './hiker.svg';
import { ReactComponent as Loader } from './loader.svg';
import { ReactComponent as Name } from './kevyn-name.svg';

const classNames = require('classnames');

const Subheader = ({ loading, location, showMenu }) => {
  const [goingUp, setGoingUp] = useState(false);
  const [hikerStyles, setHikerStyles] = useState({});

  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      if (currentScrollY >= 1) {
        setHikerStyles({
          opacity: 10000 / (currentScrollY ** 3),
        });
      } else {
        setHikerStyles({});
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  const loaderClassNames = classNames({
    Loader: true,
    loading,
  });

  const hideHiker = location.pathname === '/' || showMenu;

  const hikerClassNames = classNames({
    Hiker: true,
    'hide-hiker': hideHiker,
  });

  const nameClassNames = classNames({
    Name: true,
    'hide-name': !hideHiker,
  });

  return (
    <>
      <div className="Hiker-Bar">
        <Hiker className={hikerClassNames} style={hikerStyles} />
      </div>
      <div className="Bar">
        <span className="dot" />
        <Loader className={loaderClassNames} />
        <Name className={nameClassNames} />
      </div>

    </>
  );
};

Subheader.propTypes = {
  loading: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  loading: isAppLoading(state),
});

export default compose(withRouter, connect(mapStateToProps, undefined))(Subheader);
