import React from 'react';
import './ThreeDotsLoader.scss';
import { PropTypes } from 'prop-types';

const classNames = require('classnames');

const ThreeDotsLoader = ({ hidden, text }) => {
  const loaderClasses = classNames({
    spinner: true,
    hidden,
  });

  return (
    <div className={loaderClasses}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
      {text ? <span className="text">{text}</span> : null}
    </div>
  );
};

ThreeDotsLoader.propTypes = {
  hidden: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

ThreeDotsLoader.defaultProps = {
  text: '',
};

export default ThreeDotsLoader;
