import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './store/store';

const instance = createInstance({
  urlBase: '//analytics.kevynhale.com/"',
  siteId: 1,
  trackerUrl: '//analytics.kevynhale.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: '//analytics.kevynhale.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
