export const initialProjectsState = {
  loadingProjects: false,
};

export default (state = initialProjectsState, action) => {
  switch (action.type) {
    case 'LOADING_PROJECTS':
      return {
        ...state,
        loadingProjects: true,
      };

    case 'FINISHED_LOADING_PROJECTS':
      return {
        ...state,
        loadingProjects: false,
      };

    default:
      return state;
  }
};
