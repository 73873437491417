export const loadingAbout = () => ({
  type: 'LOADING_ABOUT',
});

export const finishedLoadingAbout = () => ({
  type: 'FINISHED_LOADING_ABOUT',
});

export const startLoadingAboutDelay = () => ({
  type: 'START_LOADING_ABOUT_DELAY',
});
