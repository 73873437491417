export const loadingPosts = () => ({
  type: 'LOADING_POSTS',
});

export const startLoadingPostsDelay = () => ({
  type: 'START_LOADING_POSTS_DELAY',
});

export const finishedLoadingPosts = () => ({
  type: 'FINISHED_LOADING_POSTS',
});
