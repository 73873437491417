/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { delayResolve } from '../../utils';
import { finishedLoadingAbout } from './AboutActions';

function* startDelay() {
  yield call(delayResolve);
  yield put(finishedLoadingAbout());
}

// eslint-disable-next-line import/prefer-default-export
export function* aboutSaga() {
  yield takeLatest('START_LOADING_ABOUT_DELAY', startDelay);
}
