export const initialContactState = {
  loading: false,
  hasError: false,
  error: null,
  emailSent: false,
};

export default (state = initialContactState, action) => {
  switch (action.type) {
    case 'RESET_FORM':
      return {
        ...initialContactState,
      };

    case 'SUBMIT_EMAIL':
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null,
      };

    case 'SUBMIT_EMAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        emailSent: true,
      };

    case 'SUBMIT_EMAIL_ERROR':
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
