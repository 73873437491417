/* eslint-disable import/prefer-default-export */
import { format, parseISO, isSameYear } from 'date-fns';

export const objToQueryString = (obj) => Object.keys(obj)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
  .join('&');

export const delayResolve = () => new Promise((resolve) => setTimeout(() => resolve(), 800));

export const delayReject = (time) => new Promise((_, reject) => setTimeout(() => {
  reject(new Error('You provided invalid creds.'));
}, time));

export const getContentReadTime = (content) => {
  const wordCount = content
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .length;

  return Math.ceil(wordCount / 200);
};

export const formatPostDate = (dateString) => {
  const formatWithYear = 'MMM d';
  const formatWithoutYear = 'MMM d, yyyy';

  const date = parseISO(dateString);
  const today = Date();

  if (isSameYear(date, today)) {
    return format(date, formatWithoutYear);
  }

  return format(date, formatWithYear);
};
