import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  fetchCategories,
  selectCategory,
} from '../../store/blogCategories/BlogCategoriesActions';
import './Categories.scss';
import ThreeDotsLoader from '../ThreeDotsLoader/ThreeDotsLoader';

const classNames = require('classnames');

const GET_TAGS = gql`
query GET_TAGS {
  tags {
    edges {
      node {
        id
        name
        tagId
        slug
      }
    }
  }
}
`;

const Category = ({
  selected, id, name, onSelect,
}) => {
  let isSelected = false;

  if (
    (selected === '' && name === 'all')
    || selected === id
  ) {
    isSelected = true;
  }

  const categoryClasses = classNames({
    selected: isSelected,
    category: true,
  });

  return (
    <div
      className={categoryClasses}
      onClick={onSelect}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      {name}
    </div>
  );
};

Category.propTypes = {
  selected: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

class BlogCategories extends Component {
  componentDidMount() {
    const { fetchCategoriesInternal } = this.props;
    fetchCategoriesInternal();
  }

  selectCategory(category) {
    const { selectCategoryInternal } = this.props;
    selectCategoryInternal(category);
  }

  render() {
    const { selected } = this.props;

    return (
      <Query query={GET_TAGS}>
        {({ loading, data }) => {
          const groupClasses = classNames({
            hidden: loading,
            'category-group': true,
          });

          const all = { node: { id: '', name: 'all', slug: '' } };

          const categories = data ? [all, ...data.tags.edges] : [all];

          return (
            <div className="Categories">
              <ThreeDotsLoader
                className="Categories-Loader"
                hidden={!loading}
              />
              <div className={groupClasses}>
                {categories.map((category) => {
                  const { name, slug } = category.node;

                  return (
                    <Category
                      key={slug}
                      name={name}
                      id={slug}
                      selected={selected}
                      onSelect={() => this.selectCategory(slug)}
                    />
                  );
                })}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

BlogCategories.propTypes = {
  selected: PropTypes.string.isRequired,
  fetchCategoriesInternal: PropTypes.func.isRequired,
  selectCategoryInternal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.blogCategories.categories,
  selected: state.blogCategories.selected,
  loading: state.blogCategories.loadingCategories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategoriesInternal: () => dispatch(fetchCategories()),
  selectCategoryInternal: (category) => dispatch(selectCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategories);
