import React from 'react';
import { NavLink } from 'react-router-dom';
import './HeaderRouteLink.scss';
import { PropTypes } from 'prop-types';

const HeaderRouteLink = ({ link, children }) => (
  <NavLink to={`/${link}`} className="page-link" activeClassName="selected">
    {children}
  </NavLink>
);

HeaderRouteLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HeaderRouteLink;
