import React from 'react';
import PageBody from '../../components/PageBody/PageBody';
import ContactForm from './ContactForm';
import ContactTemp from './ContactTemp';

const Contact = () => {
  const showContact = true;

  return (
    <PageBody>
      {showContact ? <ContactForm /> : <ContactTemp />}
    </PageBody>
  );
};

export default Contact;
