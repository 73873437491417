export const initialBlogCategoriesState = {
  loadingCategories: false,
  hasError: false,
  error: null,
  categories: [],
  selected: '',
};

export default (state = initialBlogCategoriesState, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES':
      return {
        ...state,
        loadingCategories: true,
        hasError: false,
        error: null,
        categories: [],
        selected: '',
      };

    case 'FETCH_CATEGORIES_SUCCESS':
      action.payload.categories.unshift('all');
      return {
        ...state,
        loadingCategories: false,
        categories: action.payload.categories,
      };

    case 'FETCH_CATEGORIES_ERROR':
      return {
        ...state,
        loadingCategories: false,
        hasError: true,
        error: action.payload.error,
      };

    case 'SELECT_CATEGORY':
      return {
        ...state,
        selected: action.payload.category,
      };

    default:
      return state;
  }
};
