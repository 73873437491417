import React from 'react';
import './LoaderGridItem.scss';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const LoaderGridItem = ({ loading }) => {
  const itemClasses = classNames({
    'item-loader': true,
    loading,
  });

  return <div className={itemClasses} />;
};

LoaderGridItem.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoaderGridItem;
